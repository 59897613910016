import React from "react";
import { PhoneInput } from "react-international-phone";

export const PhoneField = ({
  inputRef,
  className = "",
  title,
  id,
  value,
  setValue,
  error,
  required,
  disabled,
}) => {
  return (
    <div className={`x-form-col ${className}`}>
      <label htmlFor={id}>
        {title} {required && <span className="required">*</span>}
      </label>
      <div
        className={`x-input-text for-mobile w-input ${
          disabled ? "disabled" : ""
        }`}
      >
        <PhoneInput
          ref={inputRef}
          id={id}
          name={id}
          defaultCountry="my"
          value={value}
          onChange={(phone) => setValue(phone)}
          forceDialCode={true}
          inputStyle={{ border: 0 }}
          countrySelectorStyleProps={{}}
          dialCodePreviewStyleProps={{}}
          disabled={disabled}
        />
      </div>
      {error ? <p className="error">{error}</p> : null}
    </div>
  );
};
