import { useState } from 'react'
import DQLogo from './DQLogo'
// import NavMobileMenu from './NavMobileMenu'
// import NavMenuList from './NavMenuList'

function Header(props) {
  const [mobileMenuHide, setMobileMenuHide] = useState(true)

  return (
    <nav className="x-nav">
      <div className="x-nav-wrapper">
        <div className="x-nav-brand-group">
          <DQLogo />
        </div>
        <div className="x-nav-group">
        <div className="x-nav-menu-wrapper">
          <div className={`x-nav-menu ${mobileMenuHide ? 'for-hide' : ''}`}>
            <div className="x-nav-mobile-brand">
              <img
                src="/images/dq-logo-text-color-hr.svg"
                alt=""
                className="x-brand-logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  )
}

export default Header