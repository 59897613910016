import { ApiInstance, ApiRoutes } from "./ApiInstance";

export const isHasValue = (val) => {
  if (val === null || val === "") {
    return false;
  }
  return true;
};

export const isHasPhoneValue = (phone) => {
  if (!isHasValue(phone)) {
    return false;
  }

  if (typeof phone === "object") {
    return isHasValue(phone?.country_code) && isHasValue(phone?.number);
  }

  return false;
};
