import Select from "react-select";

const SelectInput = ({
  className = "",
  title,
  id,
  defaultValue,
  onChange,
  error,
  required,
  list,
}) => {
  return (
    <div className={`x-form-col ${className}`}>
      <label htmlFor={id}>
        {title} {required && <span className="required">*</span>}
      </label>
      <Select
        // className="basic-single x-input-select w-select"
        classNamePrefix="select"
        id={id}
        name={id}
        defaultValue={defaultValue}
        isClearable={false}
        isSearchable={true}
        options={list}
        onChange={onChange}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            minHeight: 40,
            height: 40,
            borderRadius: 24,
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            padding: 0,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected ? "#e00069" : "#fff",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
        }}
      />
      {error ? <p className="error">{error}</p> : null}
    </div>
  );
};

export default SelectInput;
