const TextInput = ({
  className = "",
  title,
  id,
  value,
  onChange,
  error,
  required,
  placeholder = "",
  maxLength = 256,
  showMoreInfo,
  onClickMoreInfo,
}) => {
  return (
    <div className={`x-form-col ${className}`}>
      <label htmlFor={id}>
        {title} {required && <span className="required">*</span>}
      </label>
      <div className="relative">
        <input
          className="x-input-text w-input"
          type="text"
          id={id}
          name={id}
          data-name={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {showMoreInfo && (
          <div className="x-input-btn" onClick={onClickMoreInfo}>
            <div className="tooltip">
              <div className="x-dialog-title for-action-icon"></div>
              <span className="tooltip-text">Click for more info</span>
            </div>
          </div>
        )}

        {/* <button className="x-input-btn" onClick={(e) => {}}>
          <MdInfoOutline className="x-input-icon" />
        </button> */}
      </div>
      {error ? <p className="error">{error}</p> : null}
    </div>
  );
};

export default TextInput;
