import axios from "axios";
import { API_BASE_URL } from "./Constants";
import ApiRoutes from "../Routes/ApiRoutes";

const ApiInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor for API calls
ApiInstance.interceptors.request.use(
  async (config) => {
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
ApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export { ApiInstance, ApiRoutes };
