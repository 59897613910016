const Modal = ({ showModal, handleCloseModal, content, button }) => {
  return (
    <div
      data-toggletarget="dialog-addons-guide"
      className={`x-dialog ${showModal ? "" : "for-hide"}`}
    >
      <div className="x-dialog-wrapper">
        <div className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}>
          <form
            id="wf-form-Guide-Dialog-Form"
            name="wf-form-Guide-Dialog-Form"
            data-name="Guide Dialog Form"
            method="get"
            data-wf-page-id="64fc904b6e1ec367837abe0b"
            data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
          >
            <div className="x-dialog-header">
              <div className="x-dialog-title for-action-icon"></div>
              <div className="x-btn-container">
                <a
                  onClick={handleCloseModal}
                  className="x-icon-btn for-simple is-close-modal w-button"
                >
                  
                </a>
              </div>
            </div>
            <div className="x-dialog-body">
              <div className="x-form-wrapper">
                <div className="x-form-row">{content}</div>
                <div className="x-form-action">
                  <div className="x-btn-container">{button}</div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="x-dialog-overlay" />
      </div>
    </div>
  );
};

export default Modal;
